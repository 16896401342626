<template>
  <div class="card card-flush pt-5" v-if="!uploaded">
    <div class="card-header">
      <div class="card-title">
        <h1 class="card-label">Import from Excel Tutorial</h1>
      </div>
      <div class="card-subtitle d-flex align-items-center">
        <div>
          <input
            @change="uploadExcel($event)"
            class="d-none"
            id="file"
            type="file"
            accept=".xlsx"
          />
          <label
            class="btn btn-success d-flex align-items-center mt-3 mt-lg-0"
            for="file"
            ><span class="material-icons-outlined me-3"> folder </span> Browse
            Excel File</label
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-center">
        <img
          style="max-height: 260px"
          class="card-img w-lg-50"
          src="/media/images/ImportFunctionTutorial.png"
          alt="Function Tutorial"
        />
      </div>
      <p class="pt-8 fs-2">
        วิธีเพื่มรถจาก Excel:
        <span class="text-dark">
          ใส่ข้อมูลใน Excel ให้ตรงกับ Column ตามรูปภาพ
          ข้อมูลในแต่ล่ะแถวจะมีความเกี่ยวข้องกัน โดยมีความใหญ่จากซ้ายไปขวา
        </span>
      </p>
    </div>
  </div>

  <div class="card card-flush pt-5" v-else>
    <div class="card-header">
      <div class="card-title">
        <h1 class="card-label">Imported Function Lists (Preview)</h1>
      </div>
      <div class="card-subtitle d-flex align-items-center">
        <div class="pt-lg-0 pt-4">
          <button
            @click="cancelUpload()"
            name="cancel import"
            class="btn btn-danger me-7"
          >
            Cancel
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#confirm"
            name="confirm import"
            class="btn btn-primary"
            :data-kt-indicator="loading ? 'on' : 'off'"
            :disabled="loading"
          >
            Import
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <Datatable
        :loading="loading"
        :tableData="tableData"
        :tableHeader="tableHeader"
      >
        <template v-slot:cell-no="{ row }">
          {{ row.no }}
        </template>
        <template v-slot:cell-type="{ row, cell }">
          <div>
            {{ row[cell - 1] }}
          </div>
        </template>
        <template v-slot:cell-name="{ row, cell }">
          <div :class="functions.includes(row[cell - 1]) && 'text-danger'">
            {{ row[cell - 1] }}
          </div>
        </template>
      </Datatable>
    </div>
  </div>

  <ConfirmModal ref="modalRef">
    <button
      @click="uploadTable()"
      type="button"
      name="confirm"
      class="btn btn-primary px-10"
    >
      Confirm
    </button>
  </ConfirmModal>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import readXlsxFile from 'read-excel-file';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { hideModal } from '@/core/helpers/dom';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { refreshTokens } from '@/core/services/JwtService';

export default {
  name: 'ImportFunction',
  components: {
    Datatable,
    ConfirmModal,
  },
  setup() {
    const router = useRouter();
    const uploaded = ref(false);
    const loading = ref(false);
    const modalRef = ref(null);
    const tableData = ref([]);
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Type',
        key: 'type',
        sortable: true,
      },
      {
        name: 'Name',
        key: 'name',
        sortable: true,
      },
    ];
    const functions = ref([]);

    onMounted(async () => {
      const { data } = await ApiService.get('function-of-car');
      functions.value = data.datas.map((func) => func.name);

      setCurrentPageBreadcrumbs(
        'Import From Excel',
        ['Import Function'],
        {},
        false
      );
    });

    const uploadExcel = async (e) => {
      const file = e.target.files[0];
      if (file.name.split('.')[file.name.split('.').length - 1] !== 'xlsx') {
        alert('Please select a valid Excel file');
        return;
      }
      uploaded.value = true;
      loading.value = true;

      const parsedExcel = await readXlsxFile(file);

      tableData.value = parsedExcel;
      loading.value = false;
    };

    const cancelUpload = () => {
      uploaded.value = false;
      tableData.value = [];
    };

    const uploadTable = async () => {
      const promises = [];
      hideModal(modalRef.value.$el);
      loading.value = true;

      try {
        for (const row of tableData.value) {
          const promise = {
            url: 'function-of-car',
            data: {
              type: row[0].charAt(0).toUpperCase() + row[1].slice(1),
              name: row[1],
            },
          };
          promises.push(promise);
        }

        await refreshTokens();
        await ApiService.all(
          promises.map((promise) => ApiService.post(promise.url, promise.data))
        );

        loading.value = false;

        await Swal.fire({
          text: 'Functions have been successfully imported.',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Go to function lists',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        router.push({ name: 'Function Lists' });
      } catch (err) {
        loading.value = false;
        const res = err.response.data.message;

        if (res === 'body.type should be equal to one of the allowed values')
          await Swal.fire({
            icon: 'error',
            buttonsStyling: false,
            text: 'Some of the types are not valid. Please check the table.',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
      }
    };

    return {
      uploaded,
      loading,
      tableData,
      tableHeader,
      uploadExcel,
      cancelUpload,
      uploadTable,
      modalRef,
      functions,
    };
  },
};
</script>

<style lang="sass" scoped>
p
  color: #1492E6
</style>
